import React from "react"

import { Link } from "gatsby"

const SignupBanner = ({ children }) => {
  return (
    <div className=" flex justify-center py-6 mb-24">
      <Link
        to="/register/"
        className="mx-auto  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 uppercase block bg-primary text-white font-bold rounded-md my-6 py-4 px-8 shadow-2xl"
      >
        {children || "Start a free trial now!"}
      </Link>
    </div>
  )
}

export default SignupBanner
