import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContainerLibraryMenu from "../../components/equipment-library-menu"
import EquipmentDimensionTable from "../../components/equipment-dimension-table"
import SignupBanner from "../../components/signupBanner"
import { typeName, convertVol, convert, volUnit, setTypeName } from "../../util"
import { graphql } from "gatsby" // to query for image data

const SetsPage = ({ data }) => {
  const set = data.restApiApi2SetsDefault.data
  const base_type = data.restApiApi2SetsDefault.base_type
  const type_name = typeName(base_type)
  let [dimension, setDimension] = useState("MM")
  let [weightDimension, setWeightDimension] = useState("KG")

  const summary = set.slots
    .flatMap(s => s.configurations[0].group.map(g => g.container_type_id))
    .reduce((map, t) => {
      let o = map.find(o => o.id == t)
      if (o) {
        o.count += 1
      } else {
        map.push({ id: t, count: 1 })
      }
      return map
    }, [])
  useEffect(() => {
    setDimension(localStorage.getItem("dimension") || "MM")
    setWeightDimension(localStorage.getItem("weightDimension") || "KG")
  }, [])

  useEffect(() => {
    const type_map = set.container_types.reduce((map, t) => {
      map[t.id] = t
      return map
    }, {})
    const rendering = set.slots.flatMap(slot =>
      slot.configurations[0].group.map(c => {
        return {
          ...type_map[c.container_type_id],
          ...c,
        }
      })
    )
    window.cargoPlannerSDK.SceneManager.init().then(() => {
      window.cargoPlannerSDK.SceneManager.createScene(
        rendering,
        "camera_only",
        1200,
        600,
        {
          cogSettings: { hide: true },
          backgroundColor: "rgb(248, 250, 252)",
        }
      ).then(canvas => {
        canvas.style.maxWidth = "100%"
        canvas.style.height = "auto"
        canvas.style.margin = "0 auto"
        document.getElementById("scene").replaceChildren(canvas)
      })
    })
  }, [set, dimension])

  const onDimensionChange = dim => {
    localStorage.setItem("dimension", dim)
    setDimension(dim)
  }

  const onWeightDimChange = dim => {
    localStorage.setItem("weightDimension", dim)
    setWeightDimension(dim)
  }

  const totalVolume = summary
    .map(({ count, id }) => {
      let hold = set.container_types.find(ct => ct.id == id)

      let volume =
        convertVol(dimension, hold.L) *
        convertVol(dimension, hold.W) *
        convertVol(dimension, hold.H || hold.max_height)

      // take contours into consideration for volume
      let contouredVolume =
        (convertVol(dimension, hold.contours?.front_top_contour_l) *
          convertVol(dimension, hold.contours?.front_top_contour_h) *
          hold.W || 0) /
          2 +
        (convertVol(dimension, hold.contours?.rear_top_contour_l) *
          convertVol(dimension, hold.contours?.rear_top_contour_h) *
          hold.W || 0) /
          2 +
        (convertVol(dimension, hold.contours?.side1_top_contour_l) *
          convertVol(dimension, hold.contours?.side1_top_contour_h) *
          hold.L || 0) /
          2 +
        (convertVol(dimension, hold.contours?.side2_top_contour_l) *
          convertVol(dimension, hold.contours?.side2_top_contour_h) *
          hold.L || 0) /
          2 +
        (convertVol(dimension, hold.contours?.front_bottom_contour_l) *
          convertVol(dimension, hold.contours?.front_bottom_contour_h) *
          hold.W || 0) /
          2 +
        (convertVol(dimension, hold.contours?.rear_bottom_contour_l) *
          convertVol(dimension, hold.contours?.rear_bottom_contour_h) *
          hold.W || 0) /
          2 +
        (convertVol(dimension, hold.contours?.side1_bottom_contour_l) *
          convertVol(dimension, hold.contours?.side1_bottom_contour_h) *
          hold.L || 0) /
          2 +
        (convertVol(dimension, hold.contours?.side2_bottom_contour_l) *
          convertVol(dimension, hold.contours?.side2_bottom_contour_h) *
          hold.L || 0) /
          2

      return count * Math.round(volume - contouredVolume)
    })
    .reduce((total, v) => total + v, 0)
  const payload =
    set.payload ||
    summary
      .map(({ id, count }) => {
        return {
          count,
          payload: set.container_types.find(ct => ct.id == id).payload,
        }
      })
      .filter(({ payload }) => payload)
      .map(({ payload, count }) => count * payload)
      .reduce((total, payload) => total + payload, 0)
  const containerCount = summary.reduce((total, { count }) => total + count, 0)
  const descriptors1 = [
    payload ? (
      <span>a payload of {convert(weightDimension, payload)}</span>
    ) : undefined,
    totalVolume ? (
      <span>
        a volume of {totalVolume}
        {volUnit(totalVolume)}
      </span>
    ) : undefined,
    <span>
      a total of {containerCount} {type_name}s
      {summary.length != containerCount && summary.length > 1
        ? ` of ${summary.length} different types`
        : ""}
    </span>,
  ].filter(v => !!v)
  return (
    <Layout>
      <SEO title="Container Library" />
      <ContainerLibraryMenu
        selectedSet={set.id}
        onDimensionChange={onDimensionChange}
        onWeightDimChange={onWeightDimChange}
        weightDim={weightDimension}
        lengthDim={dimension}
      >
        <div className="container mx-auto pt-6 pb-16 px-6 mx-auto text-lg">
          <h1 className="my-4 text-4xl font-bold leading-snug tracking-wider">
            {set.name}
          </h1>
          <div className="mb-8 space-y-4">
            {!!set.description && <p>{set.description}</p>}
            <p>
              The default layout of the {set.name} in Cargo-Planner has{" "}
              {descriptors1.map((v, index) => {
                return (
                  <span key={index}>
                    {v}
                    {index < descriptors1.length - 2 && ", "}
                    {index == descriptors1.length - 2 && " and "}
                  </span>
                )
              })}
              .
            </p>
            <p>
              Everything about this {setTypeName(base_type)} is customizable in
              Cargo-Planner
            </p>
          </div>

          <div
            id="scene"
            className="border rounded-lg"
            style={{
              maxWidth: "100%",
              cursor: "grab",
            }}
          ></div>
          <div
            className="flex flex-wrap justify-evenly"
            style={{ marginLeft: "-2rem" }}
          >
            {summary.map(({ id, count }) => {
              const hold = set.container_types.find(ct => ct.id == id)
              return (
                <EquipmentDimensionTable
                  key={id}
                  title={`${count} x ${hold.name}`}
                  allValues={[
                    {
                      values: [
                        { title: "Length", value: hold.L },
                        { title: "Width", value: hold.W },
                        { title: "Height", value: hold.H },
                        { title: "Max Height", value: hold.max_height },
                        { title: "Payload", value: hold.payload, weight: true },
                        { title: "Tare", value: hold.tare, weight: true },
                        { title: "Door Height", value: hold.door?.H },
                        { title: "Door Width", value: hold.door?.W },
                      ],
                    },
                    {
                      title: "Top Contours",
                      values: [
                        {
                          title: "Left Length",
                          value: hold.contours?.front_top_contour_l,
                        },
                        {
                          title: "Left Height",
                          value: hold.contours?.front_top_contour_h,
                        },
                        {
                          title: "Right Length",
                          value: hold.contours?.rear_top_contour_l,
                        },
                        {
                          title: "Right Height",
                          value: hold.contours?.rear_top_contour_h,
                        },
                        {
                          title: "Front Length",
                          value: hold.contours?.side1_top_contour_l,
                        },
                        {
                          title: "Front Height",
                          value: hold.contours?.side1_top_contour_h,
                        },
                        {
                          title: "Rear Length",
                          value: hold.contours?.side2_top_contour_l,
                        },
                        {
                          title: "Rear Height",
                          value: hold.contours?.side2_top_contour_h,
                        },
                      ],
                    },
                    {
                      title: "Bottom Contours",
                      values: [
                        {
                          title: "Left Length",
                          value: hold.contours?.front_bottom_contour_l,
                        },
                        {
                          title: "Left Height",
                          value: hold.contours?.front_bottom_contour_h,
                        },
                        {
                          title: "Right Length",
                          value: hold.contours?.rear_bottom_contour_l,
                        },
                        {
                          title: "Right Height",
                          value: hold.contours?.rear_bottom_contour_h,
                        },
                        {
                          title: "Front Length",
                          value: hold.contours?.side1_bottom_contour_l,
                        },
                        {
                          title: "Front Height",
                          value: hold.contours?.side1_bottom_contour_h,
                        },
                        {
                          title: "Rear Length",
                          value: hold.contours?.side2_bottom_contour_l,
                        },
                        {
                          title: "Rear Height",
                          value: hold.contours?.side2_bottom_contour_h,
                        },
                      ],
                    },
                  ]}
                  lengthDim={dimension}
                  weightDim={weightDimension}
                />
              )
            })}
          </div>
        </div>

        <div className="bg-white py-16">
          <h3 className="text-3xl text-center mt-16 leading-relaxed">
            Ship less air. Waste less time.
            <br />
            Let Cargo-Planner calculate how to fill your {set.name}.
          </h3>
          <SignupBanner />
        </div>
      </ContainerLibraryMenu>
    </Layout>
  )
}

// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.
export const query = graphql`
  query($id: String) {
    restApiApi2SetsDefault(id: { eq: $id }) {
      name
      id
      base_type
      data {
        id
        name
        slots {
          configurations {
            group {
              container_type_id
              rotation
              position {
                x
                y
                z
              }
              position_name
              props {
                max_height
              }
            }
          }
        }
        container_types {
          H
          L
          W
          base_type
          description
          floor_height
          id
          max_height
          name
          no_end_walls
          no_roof
          no_side_walls
          payload
          qty
          tare
          items {
            H
            L
            W
            WT
            color
            not_stackable
            pos {
              x
              y
              z
            }
            qty
          }
          contours {
            auto_bottom_spacer_h
            front_bottom_contour_h
            front_bottom_contour_l
            front_top_contour_h
            front_top_contour_l
            rear_bottom_contour_h
            rear_bottom_contour_l
            rear_top_contour_h
            rear_top_contour_l
            side1_bottom_contour_h
            side1_bottom_contour_l
            side1_top_contour_h
            side1_top_contour_l
            side2_bottom_contour_h
            side2_bottom_contour_l
            side2_top_contour_h
            side2_top_contour_l
          }
          parts {
            H
            L
            W
            id
            stick_to
            type
          }
        }
      }
    }
  }
`

export default SetsPage
