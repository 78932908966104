import React from "react"
import PropTypes from "prop-types"
const convert = (value, dim) => {
  switch (dim) {
    case "M":
      return Math.round(value * 1000) / 1000 + " m"
    case "CM":
      return Math.round(value * 100 * 10) / 10 + " cm"
    case "MM":
      return Math.round(value * 1000) + " mm"
    case "IN":
      return Math.round(value * 39.3701 * 10) / 10 + '"'
  }
}
const convertWeight = (value, dim) => {
  switch (dim) {
    case "KG":
      return Math.round(value) + " kg"
    case "LB":
      return Math.round(value * 2.20462) + " lbs"
  }
}
const EquipmentDimensionTable = ({
  values,
  lengthDim,
  weightDim,
  title,
  allValues,
}) => {
  let vs = values ? [{ title: undefined, values }] : allValues
  vs = vs.map(vss => {
    return { title: vss.title, values: vss.values.filter(v => !!v.value) }
  })
  vs = vs.filter(vss => vss && vss.values.length > 0)

  if (vs.length == 0) {
    return <React.Fragment />
  }
  return (
    <div className="border px-8 pt-6 pb-8 bg-white flex-grow mt-8 ml-8 rounded-lg">
      <h2 className="text-2xl text-center mb-4">{title}</h2>
      {vs.map((vss, index) => (
        <div key={index}>
          {vss.title && (
            <h2 className="m-auto text-center mt-8">{vss.title}</h2>
          )}
          <table className="m-auto">
            <thead className="">
              <tr className="">
                {vss.values.map(h => (
                  <th
                    className="border-x px-6 py-4 border-b text-center"
                    key={title + h.title}
                  >
                    {h.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {vss.values.map(h => (
                  <td
                    className="px-6 py-4 text-center"
                    key={title + h.title + "value"}
                  >
                    {lengthDim && !h.weight
                      ? convert(h.value, lengthDim)
                      : convertWeight(h.value, weightDim)}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </div>
  )
}

EquipmentDimensionTable.propTypes = {
  values: PropTypes.array,
  lengthDim: PropTypes.string,
  weightDim: PropTypes.string,
  title: PropTypes.string,
}

export default EquipmentDimensionTable
